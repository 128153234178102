import styled from 'styled-components';

export const VideoWrapper = styled.div`
  & > * {
    height: 100%;
    display: flex;
    align-items: center;

    .video-js.video-justify-content.video-js {
      height: 100%;
      @media (min-width: ${props => props.theme.viewports.tablet}px) {
        padding-top: 0;
      }
    }
    .vjs-poster {
      background-size: cover;
    }
  }
`;

export const HeroBackground = styled.div<{
  whiteBackground?: boolean;
  backgroundImage?: string;
  backdrop?: boolean;
}>`
  border-radius: 3px;
  overflow: hidden;
  background-color: ${props => (props.whiteBackground ? 'white' : null)};
  background-image: ${props =>
    props.backgroundImage ? `url(${props.backgroundImage})` : null};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  ${({ backdrop }) =>
    backdrop &&
    `
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(64, 64, 64, 0.3);
      }
    `};
`;

export const TextWrapper = styled.div<{ transparentColor?: string }>`
  background-color: transparent;
  z-index: 11;
  background-color: ${({ transparentColor }) =>
    transparentColor ? transparentColor : null};
`;

export const TextWrapperInner = styled.div`
  height: 100%;
`;

export const Title = styled.h1<{ dark?: boolean; as?: 'h1' | 'h2' | 'h3' }>`
  color: ${props => (props.dark ? `#fff` : `#253238`)};
  font-weight: 600;
  line-height: 1.2;
  text-wrap: balance;
  margin: 0;

  font-size: ${({ as }) => {
    switch (as) {
      case 'h1':
        return '30px';
      case 'h2':
        return '26px';
      case 'h3':
        return '22px';
      default:
        return '30px';
    }
  }};

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    font-size: ${({ as }) => {
      switch (as) {
        case 'h1':
          return '34px';
        case 'h2':
          return '30px';
        case 'h3':
          return '26px';
        default:
          return '34px';
      }
    }};
    margin-bottom: 20px;
  }
`;

export const SubTitle = styled.h3<{ dark?: boolean }>`
  margin: 0;
  margin-bottom: 20px;
  color: ${props => (props.dark ? `#fff` : `#242424`)};
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  text-wrap: balance;

  :empty {
    display: none;
  }
`;

export const SubscriptionTag = styled.div<{ dark?: boolean }>`
  position: relative;
  color: ${props => props.theme.colors.neutrals.black};
  color: ${props => props.dark && `#fff`};
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;

  i {
    &:before {
      color: ${props => props.theme.colors.primary['pink-1']};
    }
  }
`;

export const Paragraph = styled.div<{ dark?: boolean }>`
  font-size: 14px;
  color: ${props => (props.dark ? `#fff` : `#242424`)};
  text-wrap: balance;
  line-height: 26px;
  margin: 0 0 20px 0;

  p {
    margin: 0;
  }

  :empty {
    display: none;
  }
`;

export const Disclaimer = styled.span<{ dark?: boolean }>`
  color: ${props => (props.dark ? `#fff` : `#242424`)};
  font-size: 12px;
  font-style: italic;
  line-height: 23px;
  margin: 0;
  margin-bottom: 20px;

  :empty {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  gap: 14px;

  :empty {
    display: none;
  }

  button {
    margin-right: 10px;
  }
`;

export const CategoryTitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin: 0;
`;

export const HeroCaption = styled.div`
  background-color: rgba(102, 124, 145, 0.4);
  padding: 11px 0;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  width: 100%;
  z-index: 11;
`;
