import styled from 'styled-components';
import { Trans } from '@lingui/react';
import Button from '@components/buttons';
import {
  BestPriceLabel,
  SubscriptionBox,
  SubscriptionBoxBilled,
  SubscriptionBoxTitle,
  SubscriptionBoxOldPrice,
  SubscriptionBoxPriceWrapper as subscriptionBoxPriceWrapper,
  SubscriptionBoxPrice,
} from '@components/templates/showcase/components';
import { PricingSubscriptionFieldsFragment } from '@gql/generated';
import { cn } from '@lib/tw';

const SubscriptionBoxPriceWrapper = styled(subscriptionBoxPriceWrapper)`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 6px;

  ${SubscriptionBoxPrice} {
    margin: 0;
  }
`;

const SUBSCRIPTION_OPTIONS = {
  12: {
    value: 'yearly',
    title: <Trans id="pricing_section.plan.annually.title" />,
    description: <Trans id="pricing_section.plan.annually.body" />,
    cycle: <Trans id="pricing_section.plan.annually.label" />,
  },
  3: {
    value: 'quarterly',
    title: <Trans id="pricing_section.plan.quarterly.title" />,
    description: <Trans id="pricing_section.plan.quarterly.body" />,
    cycle: <Trans id="pricing_section.plan.quarterly.label" />,
  },
  1: {
    value: 'monhtly',
    title: <Trans id="pricing_section.plan.monthly.title" />,
    description: <Trans id="pricing_section.plan.monthly.body" />,
    cycle: <Trans id="pricing_section.plan.monthly.label" />,
    first_cycle: <Trans id="pricing_section.plan.first_month.label" />,
  },
};

type Props = {
  className?: string;
  subscription: PricingSubscriptionFieldsFragment;
  toHighlight?: 'monthly' | 'quarterly' | 'yearly';
  onPlanSelect(subscriptionName: string): void;
};
const PricingSubscriptionCard = ({
  className = '',
  subscription,
  toHighlight,
  onPlanSelect,
}: Props) => {
  const highlight = toHighlight === subscription.subscriptionPlan;
  const text = SUBSCRIPTION_OPTIONS[subscription.months];
  let oldPrice;
  if (
    // if the susbcription has a discount we show the old price crossed
    subscription.discountedAmount &&
    subscription.discountedAmount !== subscription.amount
  ) {
    oldPrice = (
      <SubscriptionBoxOldPrice>
        {subscription.amount + subscription.currency}
      </SubscriptionBoxOldPrice>
    );
  }

  return (
    <SubscriptionBox
      className={cn(
        'SubscriptionBox' + (highlight ? 'Card--HighlightedCard' : ''),
        className
      )}
    >
      {highlight ? (
        <BestPriceLabel>
          <Trans id="pricing_section.plan.best_value" />
        </BestPriceLabel>
      ) : null}
      <SubscriptionBoxTitle>{text?.title}</SubscriptionBoxTitle>
      {oldPrice}
      <SubscriptionBoxPriceWrapper>
        <SubscriptionBoxPrice>
          {subscription.discountedAmount ?? subscription.amount}
          {` `}
          {subscription.currency}
        </SubscriptionBoxPrice>
        {` / `}
        {subscription?.discountedAmount && subscription.months === 1
          ? text?.first_cycle
          : text?.cycle}
      </SubscriptionBoxPriceWrapper>
      <SubscriptionBoxBilled>
        {subscription.shortDescription ||
          subscription.description ||
          text?.description}
      </SubscriptionBoxBilled>
      <Button
        className="Card--ApplyButton"
        appearance="dark-blue"
        onClick={() => {
          onPlanSelect(subscription.subscriptionName);
        }}
      >
        <Trans id="pricing_section.plan.button_text" />
      </Button>
    </SubscriptionBox>
  );
};

export default PricingSubscriptionCard;
